import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Box from '@mui/material/Box';
import './AdminProductNew.css'
import Axios from '../../../Axios';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import togglebtn from '../../../img/togglebtn.png'
import Menuclose from '../../../img/Menuclose.png'
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import AdminMenuSide from '../AdminMenuSide';
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Toaster, toast } from 'react-hot-toast'
import loader from '../../../img/mintLoaderNew.gif'
import Web3 from 'web3'
import Abi from '../../../web3/Abi/contractAbi.json'
import web3SendTransaction from './web3SendTransaction'
import consts from '../../../constants';
// import Moralis from 'moralis'


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: 500,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 500],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#fff',
  border: 'none',
  p: 4,
  borderRadius: '20px'
};


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),

  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));



const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  stickycls: {
    position: 'sticky',
    top: '0',
    zIndex: '999',
  },
  reasonBlock: {
    fontSize: '24px',
    fontWeight: '600'
  }
});


const AdminProductApproved = () => {

  const web3 = new Web3(consts.polygon_url)
  const classes = useStyles();
  const isMounted = useRef(false);
  const [menuOpen, setMenuOpen] = useState(true)
  const [Brands, setBrands] = useState([])
  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const [total, setTotal] = useState(0)

  const location = useLocation()
  const navigate = useNavigate()

  const [datas, setDatas] = useState()
  const [datas1, setDatas1] = useState()

  const [productid, setProductid] = useState([])
  const [productid2, setProductid2] = useState([])

  const [status, setStatus] = useState(false)

  const [dtsStatus, setDtsStatus] = useState('0')

  const [active, setActive] = useState(false)
  // const [totalProduct, setTotalProduct] = useState(0)
  useEffect(() => {
    if (location?.state?.data) {
      setDatas1(location?.state?.data)
      setDtsStatus(location?.state?.data?.status)
      setDatas(location?.state?.data?.product_details[0])
      setTotal(location?.state?.data?.product_details?.length)
      if (location?.state?.data?.product_details.length < 3) {
        const data = [
          location?.state?.data?.product_details[0]?.product_id,
          location?.state?.data?.product_details[1]?.product_id,
          location?.state?.data?.product_details[2]?.product_id
        ]

        var dat = []

        for (let i = 0; i < location?.state?.data?.product_details.length; i++) {
          const element = location?.state?.data?.product_details[i];
          if (i < 3) {
            dat.push(element.product_id)
          }
        }
        setProductid(data)
      } else {
        var dat = []
        for (let i = 0; i < location?.state?.data?.product_details.length; i++) {
          const element = location?.state?.data?.product_details[i];
          if (i < 3) {
            dat.push(element.product_id)
          }
        }

      }

      if (location?.state?.data?.product_details.length > 3) {
        var dat = []
        for (let i = 0; i < location?.state?.data?.product_details.length; i++) {
          const element = location?.state?.data?.product_details[i];
          if (i < 2) {
            dat.push(element.product_id)
          }
        }
        setProductid(dat)
        var dt = []
        for (let j = location?.state?.data?.product_details.length - 1; j > location?.state?.data?.product_details.length - 3; j--) {
          const element = location?.state?.data?.product_details[j];
          if (location?.state?.data?.product_details.length > 3) {
            dt.push(element.product_id)
          }
        }
        setProductid2(dt)
      } else {

      }

    }
  }, [location.state])
  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  useEffect(() => {
    // getBrands();  
    if (!isMounted.current) {
      getBrands();
      isMounted.current = true;
    }
    else {
      console.log("fetched")
    }
  }, [])

  const getBrands = async () => {
    try {
      const { data } = await Axios.get('/users/get_all_brand',
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      )
      setBrands(data)
      if (data?.success) {
        console.log("Data", data?.result)

      }

    } catch (error) {
      console.log(error, "err")
    }

  }

  // const ApproveProduct = async (dt) => {
  //   const toastId = toast.loading('Loading...');
  //   try {
  //     setStatus(true)
  //     setActive(true)
  //     var first = 1;
  //     var last = 4
  //     var suTotal = total;
  //     for (let i = 1; i <= suTotal; i++) {
  //       if (((suTotal - first) < 4)) {
  //         i += 2
  //         const { data } = await Axios.post(`/users/approveProduct`, {
  //           product_id: datas1?._id,
  //           status: dt,
  //           first: first - 1,
  //           last: suTotal - 1
  //         },
  //           {
  //             headers: {
  //               Authorization: window.localStorage.getItem("AdminToken"),
  //             },
  //           })
  //         if (data.success === true) {
  //           const dts = await Axios.post('/users/approveProductAmount', {
  //             productId: datas1?._id
  //           }, {
  //             headers: {
  //               Authorization: window.localStorage.getItem("AdminToken"),
  //             }
  //           })
  //           if (dts.data.success === true) {
  //             toast.dismiss(toastId);
  //             toast.success(data?.message)
  //             setTimeout(() => {
  //               navigate(`/products/all`)
  //             }, 1000);
  //           } else {
  //             toast.dismiss(toastId);
  //             toast.error(dts?.data?.message)
  //           }

  //           // toast.update(id, { render: data?.message, type: "success", isLoading: false });
  //           // navigate(`/products/all`)
  //         } else {
  //           // toast.dismiss(toastId);
  //           toast.dismiss(toastId);
  //           toast.error(data?.message)

  //           // setTimeout(() => {
  //           //   navigate(`/products/all`)
  //           // }, 2000);
  //         }
  //         setActive(false)
  //         setStatus(false)
  //       } else {
  //         console.log(first - 1, last - 1, "last")
  //         const { data } = await Axios.post(`/users/approveProduct`, {
  //           product_id: datas1?._id,
  //           status: dt,
  //           first: first - 1,
  //           last: last - 1
  //         },
  //           {
  //             headers: {
  //               Authorization: window.localStorage.getItem("AdminToken"),
  //             },
  //           })

  //         if (data.success === true) {
  //           if (suTotal <= 4) {
  //             const dts = await Axios.post('/users/approveProductAmount', {
  //               productId: datas1?._id
  //             }, {
  //               headers: {
  //                 Authorization: window.localStorage.getItem("AdminToken"),
  //               }
  //             })
  //             if (dts?.data?.success === true) {
  //               setActive(false)
  //               setStatus(false)
  //               toast.dismiss(toastId);
  //               toast.success(data?.message)
  //               setTimeout(() => {
  //                 navigate(`/products/all`)
  //               }, 1000);
  //             } else {
  //               setActive(false)
  //               setStatus(false)
  //               toast.dismiss(toastId);
  //               toast.success(data?.message)
  //               setTimeout(() => {
  //                 navigate(`/products/all`)
  //               }, 1000);
  //             }
  //           }
  //           i += 2
  //           first += 4
  //           last += 4
  //         } else {
  //           setActive(false)
  //           setStatus(false)
  //           toast.dismiss(toastId);
  //           toast.error(data?.message)
  //           break;
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     setActive(false)
  //     setStatus(false)
  //     toast.dismiss(toastId);
  //     navigate(`/products/all`)
  //     console.log("🚀 ~ ApproveProduct ~ error:", error)
  //   }
  // }

  const [countss, setCount] = useState(0)



  const ApproveProduct = async (dt) => {
    // const toastId = toast.loading('Loading...');
    try {
      setStatus(true)
      setActive(true)
      const { data } = await Axios.post(`/users/approveProduct`, {
        product_id: datas1?._id,
        status: dt
      },
        {
          headers: {
            Authorization: window.localStorage.getItem("AdminToken"),
          },
        })
      if (data?.success === true) {
        const prod = await Axios.post(`/users/web3Transaction`, {
          product_id: datas1?._id,
        }, {
          headers: { Authorization: window.localStorage.getItem("AdminToken"), }
        })
        if (prod?.data?.success === true) {
          var products = prod?.data?.result?.product_details
          var wallet = prod?.data?.wallet
          var contractInstance = await new web3.eth.Contract(Abi, consts?.contractAddress)
          if (products?.length > 0) {
            var count = await web3.eth.getTransactionCount(wallet?.public)
            var smallCount = 0
            const gasPrice = await web3.eth.getGasPrice();
            var gasfeeiwithoutfee = 0
            var transactionFee = []
            var arr = []
            for (let i = 0; i < products.length; i++) {
              const element = products[i];
              var gasAmount1 = await contractInstance.methods.safeMint(wallet?.public, element?.ipfsLink).estimateGas({ from: wallet?.public });
              // const calc = Number(gasAmount1) * Number(gasPrice)
              // const clc = Number(web3.utils.fromWei(calc, 'ether')) + 0.01
              // const calcFinal = web3.utils.toWei(clc, 'ether')
              const txObject = {
                from: wallet?.public,
                nonce: i === 0 ? count : count + smallCount,
                to: consts?.contractAddress,
                gas: gasAmount1,
                // gasPrice: "1500000016",
                value: "0x0",
                data: contractInstance.methods.safeMint(wallet?.public, element?.ipfsLink).encodeABI(),
              }
              smallCount = smallCount + 1
              setCount(i + 1)
              const trans = await web3SendTransaction(txObject, wallet?.private)
              arr.push(trans)
              transactionFee.push((trans?.gasUsed * gasPrice))
              const feesssss = Math.round(((trans?.gasUsed * gasPrice) * 10) / 100)
              gasfeeiwithoutfee = gasfeeiwithoutfee + feesssss
            }

            const amountTxObject = {
              from: wallet?.public,
              nonce: await web3.eth.getTransactionCount(wallet?.public),
              to: wallet?.adminPublic,
              value: (gasfeeiwithoutfee).toString(),
              gas: '21000'
            }

            const trans1 = await web3SendTransaction(amountTxObject, wallet?.private)
            console.log("🚀 ~ ApproveProduct ~ trans1:", trans1)
            const transsss = await Axios.post(`/users/transferAmountsBlock`, {
              product_id: datas1?._id,
              transaction: arr,
              transactionFee: transactionFee,
              transaction1: trans1,
              transactionFee1: gasfeeiwithoutfee
            }, {
              headers: { Authorization: window.localStorage.getItem("AdminToken"), }
            })
            if (transsss?.data?.success === true) {
              setActive(false)
              setStatus(false)
              // toast.dismiss(toastId);
              toast.success("Product Approved Successfully")
              setTimeout(() => {
                navigate(`/products/all`)
              }, 1000)
            }

          }
        } else {
          setActive(false)
          setStatus(false)
          // toast.dismiss(toastId);
          toast.error("Something Went Wrong")
        }
      } else {
        setActive(false)
        setStatus(false)
        // toast.dismiss(toastId);
        toast.error(data?.message)
      }
    } catch (error) {
      setActive(false)
      setStatus(false)
      // toast.dismiss(toastId);
      setTimeout(() => {
        navigate(`/products/all`)
      }, 1000)
      toast.error("Something Went Wrong")
      console.log("🚀 ~ ApproveProduct ~ error:", error)
    }
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const checkMoralis = async () => {
  //   try {
  //     await Moralis.start({ apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImM2ZWRjZjZkLTE3MjAtNDM0Ni1hMTY3LTBjMzgzODFiMmEyZiIsIm9yZ0lkIjoiMzYwOTk1IiwidXNlcklkIjoiMzcxMDA2IiwidHlwZUlkIjoiODE1ZjAzM2ItZDNhMy00NzI4LWE2YTMtY2QyNmUxY2VkMDNkIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTcyNjU0MTMsImV4cCI6NDg1MzAyNTQxM30.Q8LWcEYDLMPQVhWKjI6wn2_Oz5Ou1LycNctgYmruwAA" });

  //     const response = await Moralis.EvmApi.ipfs.uploadFolder({
  //       abi: {
  //         name: "ramesh",
  //         description: "ramesh",
  //         image: "images"
  //       }
  //     })
  //     console.log("🚀 ~ checkMoralis ~ response:", response)
  //   } catch (error) {
  //     console.log("🚀 ~ checkMoralis ~ error:", error)

  //   }
  // }

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {
        active === true &&
        <>
          <div className='loaders-load'>
            <img src={loader} />
          </div>
          <div className='loaders-load text'>
            <div className='please-load-text'>Please Dont Go Back or Close The Window Until the Transaction is Completed.</div>
            <div className='please-load-para'>{countss}/{total}</div>
          </div>

        </>
      }

      < div className={active === true ? 'sellerpage sellerpage-admin admin-product-approve blurs' : 'sellerpage sellerpage-admin admin-product-approve'}  >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.stickycls}>
              <Item className={classes.headercls}>
                <Header />
              </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {
                active === true ?
                  <Item className={classes.bodyclass}>
                    <div className='togglebtn' ><img src={togglebtn} alt="togglebtn" /></div>
                    <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                      <div className='togglebtn-close' ><img src={Menuclose} alt="Menuclose" /></div>
                      <AdminMenuSide />
                    </div>
                  </Item>
                  :
                  <Item className={classes.bodyclass}>
                    <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn" /></div>
                    <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
                      <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose" /></div>
                      <AdminMenuSide />
                    </div>
                  </Item>
              }

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='filter-main-seller' onClick={closeMenuHandler} >


              {/* 
            <div className='start-end-date calender-date'>
              <div className='filter-box'>
                <BrandsFilterPopup />
                <Button className='filter-color'>{<RefreshIcon color='primary' />}Filter Reset</Button>
              </div>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                  <DemoItem>
                    <DatePicker
                      defaultValue={yesterday}
                      
                      views={['year', 'month', 'day']}
                    />
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
            </div> */}

              {/* <AddBrandsPopup/> */}
              {
                active === true ?
                  <Link to='' className='togglebtn-close'><img src={Menuclose} alt="Menuclose" /></Link>
                  :
                  <Link to='/products/all' className='togglebtn-close'><img src={Menuclose} alt="Menuclose" /></Link>

              }


              <div id="alert-dialog-description" className='product-head bag-grid'>
                {
                  datas?.main_image ?
                    <div className="logo-dt">
                      <img src={datas?.main_image} alt="bag" />
                    </div>
                    : <></>
                }
                {
                  datas?.Detail1_image ?
                    <div className="logo-dt">
                      <img src={datas?.Detail1_image} alt="bag" />
                    </div> :
                    <></>
                }
                {datas?.Detail2_image ?
                  <div className="logo-dt">
                    <img src={datas?.Detail2_image} alt="bag" />
                  </div>
                  :
                  <></>
                }
                {datas?.Detail3_image ?
                  <div className="logo-dt">
                    <img src={datas?.Detail3_image} alt="bag" />
                  </div>
                  :
                  <></>
                }

              </div>

              <div className="product-box-direct">


              </div>

              <Grid container spacing={0} className='contain-wid'>

                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="product-detail-box">
                    <p className="product-head-title">Product</p>
                    <p><b>{datas?.Product_Name ? datas?.Product_Name : '-'}</b></p>
                    <p><b>Short description</b></p>
                    <p className="sub-para">{datas?.product_description ? datas?.product_description : "-"} </p>
                    {/* <p><b>Long  description</b></p>
                  <p className="sub-para">Bolso de mano luis vution echo con piel devaca </p> */}
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="client-id">
                    <div className="client-details">
                      {/* <p className="client-id-head">Client ID </p>
                      <p className="id-value">xxx1112s</p> */}
                    </div>
                    <div className="client-details">
                      <p className="client-id-head">ID PRODUCT </p>
                      {productid?.map((val) => {
                        return (
                          <p className="id-value">{val}</p>
                        )
                      })}
                      {/* <div>.</div>
                      <div>.</div>
                      <div>.</div>
                      <div>.</div>
                      <div>.</div> */}
                      {productid2?.map((val) => {
                        return (
                          <p className="id-value">{val}</p>
                        )
                      })}

                    </div>
                    <div className="client-details">
                      <p className="client-id-head">Category</p>
                      <p className="id-value">{datas?.product_category ? datas?.product_category : '-'}</p>
                    </div>
                    <p className="client-id-head">Status Product</p>
                    <p className="id-value">{datas1?.status === '0' ? 'Pending...' : <>{datas1?.status === '1' ? 'Approved' : 'Declined'}</>}</p>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="product-detail-box">
                    <p><b>Measures</b></p>
                    <p className="louis-name">{datas?.Measures ? datas?.Measures : '-'}</p>
                    <p><b>Unit of Measurement</b></p>
                    <p className="louis-name">{datas?.Unit_of_measurement ? datas?.Unit_of_measurement : '-'}</p>
                    <p><b>Material</b></p>
                    <p className="louis-name">{datas?.material ? datas?.material : '-'}</p>
                    <p><b>Ingredient</b></p>
                    <p className="louis-name">{datas?.ingredients ? datas?.ingredients : '-'}</p>

                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                  <div className="inital-block">
                    <div className="brand-name">
                      <p><b>Location</b></p>
                      <p className="louis-name">{datas?.departure_location ? datas?.departure_location : '-'}</p>
                      <Link to="/traceability" className="map-loc">Click here to see the Map</Link>
                      {/* <p className="louis-name">D1113s</p> */}
                      <p><b>Brand</b></p>
                      <p className="louis-name">{datas?.brand_name ? datas?.brand_name : '-'}</p>
                      <p><b>Initial Stock</b></p>
                      <p className="louis-name">{datas?.Initial_stock ? datas?.Initial_stock : '-'}</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className='brand-action'>
                {
                  dtsStatus === '0' ?
                    <>
                      {
                        active === true ?
                          <Link to='' className='approve'  >Approve</Link>
                          :
                          <Link to='' className='approve' onClick={() => { ApproveProduct('1') }} >Approve</Link>

                      }

                      {/* <Link className='cancel' onClick={() => { ApproveProduct('2') }} >Decline</Link> */}
                    </>
                    :
                    <>
                      {
                        dtsStatus === '1' ?
                          // <Link className='cancel' onClick={() => { ApproveProduct('2') }} >Decline</Link>
                          <></>
                          :
                          <>
                            {
                              active === true ?
                                <Link to='' className='approve'  >Approve</Link>
                                :
                                <Link to='' className='approve' onClick={() => { ApproveProduct('1') }} >Approve</Link>

                            }

                          </>

                      }
                    </>
                }

                {/* <Button onClick={handleOpen}>Open modal</Button> */}
              </div>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className='CloseOption-pop-up' onClick={handleClose}><HighlightOffIcon /></div>
                  <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
                    Select the type of imported product
                  </Typography>
                </Box>
              </Modal>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.footercls}>
                <Footer />
              </Item>
            </Grid>

          </Grid>
        </Box>
      </div >
    </>

  )
}

export default AdminProductApproved
