import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import RegisterTrial from './Components/RegisterTrial/RegisterTrial';
import TokenVerify from "./Components/tokenVerify/tokenverify";
import consts from './constants';
import AdminDashboard from './Components/Admin/AdminDashboard/AdminDashboard';
import AdminProducts from './Components/Admin/AdminProducts/AdminProducts';
import AdminCustomer from './Components/Admin/AdminCustomer/AdminCustomer';
import AdminProductMenu from './Components/Admin/AdminProductsMenu/AdminProductMenu';
import AdminTraceability1 from './Components/Admin/AdminTraceability/AdminTraceability1';
import AdminTraceability2 from './Components/Admin/AdminTraceability/AdminTraceability2';
import AdminTraceability3 from './Components/Admin/AdminTraceability/AdminTraceability3';
import AdminTraceability4 from './Components/Admin/AdminTraceability/AdminTraceability4';
import WalletPage from './Components/Admin/AdminTraceability/WalletPage';
import WalletPage2 from './Components/Admin/AdminTraceability/WalletPage2';
import WalletPage3 from './Components/Admin/AdminTraceability/WalletPage3';
import AdminBrands from './Components/Admin/AdminBrands/AdminBrands';
import Admintaffs from './Components/Admin/AdminStaffs/AdminStaffs';
import AdminPostSales from './Components/Admin/AdminPostSales/AdminPostSales';
import AdminBlockchain from './Components/Admin/AdminBlockchain/AdminBlockchain';
import AdminInvoice from './Components/Admin/AdminInvoice/AdminInvoice';
import QrProduct from './Components/Admin/AdminProducts/QrProduct';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import AdminBrandsApprove from './Components/Admin/AdminBrands/AdminBrandsApprove';
import AdminBrandStaff from './Components/Admin/AdminBrands/AdminBrandStaff';
import AdminBrandSeller from './Components/Admin/AdminBrands/AdminBrandSeller';
import CreateItem from './Components/Admin/CreateItem/CreateItem';
import AdminProductApproved from './Components/Admin/AdminProducts/AdminProductApproved';
import AdminSellers from './Components/Admin/AdminSellers/AdminSellers';
import AdminSellersAuth from './Components/Admin/AdminSellersAuth/AdminSellersAuth';
import Dashboard from './Components/Brand/Dashboard/Dashboard';
import AdminProfile from './Components/Admin/AdminProfile/AdminProfile';
import Forgotmail from './Components/ForgetPassword/Forgotmail'

import SubAdminList from './Components/Admin/SubAdminList/SubAdminList'
import Subadmin from './Components/Admin/AddSubAdmin/AdminProfile'

import UserDashboard from "./Components/User/UserDashboard"
import ProductAllocate from './Components/Admin/ProductAllocation/ProductAllocat';
import ProductDetail from './Components/User/ProductDetail/ProductDetail';
import UserLoginNew from "./Components/User/UserLoginNew/UserLoginNew"
import RegisterNew from './Components/User/UserLoginNew/RegisterNew';
import User1 from './Components/User/UserLoginNew/User-1';
import RecoverNew from './Components/User/UserLoginNew/RecoverNew';
import RecoverEmail from './Components/User/UserLoginNew/RecoverEmail';
import PaswdChange from './Components/User/UserLoginNew/PaswdChange';

import MyProfile from './Components/User/MyProfile/MyProfile'



import Myorders from './Components/User/MyOrders/Myorders';
import Home from "./Components/User/Homepage/Home"
import ExtendWarrenty from './Components/User/ExtendedWarrenty/ExtendWarrenty'
import ExtendWarrent2 from './Components/User/ExtendedWarrenty/ExtendWarrent2'
import ChangeOwner from './Components/User/ChangeOwner/ChangeOwner';
import EnterCode from './Components/User/ChangeOwner/EnterCode';
import PurchaseSuccess from './Components/User/ChangeOwner/PurchaseSuccess';

import GenerateCertificate1 from "./Components/User/GenerateCertificate/GenerateCertificate1"
import GenerateCertificate2 from './Components/User/GenerateCertificate/GenerateCertificate2';
import GenerateCertificate3 from './Components/User/GenerateCertificate/GenerateCertificate3';
import MyProductList from './Components/User/MyProducts/MyProductsList';
import MyProductView from './Components/User/MyProducts/MyProductView';
import PremiumBrands from './Components/User/PremiumBrands/PremiumBrands';
import PremiumChat from './Components/User/PremiumBrands/PremiumChat';
import Customer from './Components/Admin/Customer/Customer';
import BrandBlockChain from './Components/Brand/BrandBlockchain/BrandBlockchain';
import BrandInvoice from './Components/Brand/BrandInvoice/BrandInvoice';
import BrandProducts from './Components/Brand/BrandProducts/BrandProducts';
import BrandPostSales from './Components/Brand/BrandPostSales/BrandPostSales';
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import Purchase_Success from './Components/User/Purchase_Success';
import FeedBack from './Components/Brand/FeedBack/FeedBack';

function App() {

  return (
    <>

      <Router>
        <Routes>
          <Route index="index" path={`${consts.route}/register`} element={<Register />} />
          <Route index="index" path={`${consts.route}/register-trial`} element={<RegisterTrial />} />
          <Route index="index" path={`${consts.route}/`} element={<Login />} />
          {/* <Route index="index" path={`${consts.route}/`} element={<UserLoginNew />} /> */}
          <Route index="index" path={`${consts.route}/reset-password`} element={<ForgetPassword />} />
          <Route index="index" path={`${consts.route}/forgotpassword/:token/:email`} element={<ForgetPassword />} />
          <Route index="index" path={`${consts.route}/forgot-mail`} element={<Forgotmail />} />

          <Route index="index" path={`${consts.route}/tokenpage/:token`} element={<TokenVerify />} />
          <Route path={`${consts.route}/dashboard`} element={<AdminDashboard />} />
          <Route path={`${consts.route}/products/:id`} element={<PrivateRoute><AdminProducts /></PrivateRoute>} />
          <Route path={`${consts.route}/customer`} element={<PrivateRoute><AdminCustomer /></PrivateRoute>} />
          <Route path={`${consts.route}/admin/admin-brand`} element={<AdminBrands />} />
          <Route path={`${consts.route}/admin/admin-staff`} element={<PrivateRoute><Admintaffs /> </PrivateRoute>} />
          <Route path={`${consts.route}/products-menu`} element={<PrivateRoute><AdminProductMenu /></PrivateRoute>} />
          <Route path={`${consts.route}/traceability`} element={<PrivateRoute><AdminTraceability1 /> </PrivateRoute>} />
          <Route path={`${consts.route}/wallet`} element={<PrivateRoute><WalletPage /> </PrivateRoute>} />
          <Route path={`${consts.route}/wallet2`} element={<PrivateRoute><WalletPage2 /> </PrivateRoute>} />
          <Route path={`${consts.route}/wallet3`} element={<PrivateRoute><WalletPage3 /> </PrivateRoute>} />

          <Route path={`${consts.route}/admin/admin-traceability2`} element={<PrivateRoute><AdminTraceability2 /></PrivateRoute>} />
          <Route path={`${consts.route}/admin/admin-traceability3`} element={<PrivateRoute><AdminTraceability3 /> </PrivateRoute>} />
          <Route path={`${consts.route}/admin/admin-traceability4`} element={<PrivateRoute><AdminTraceability4 /></PrivateRoute>} />
          <Route path={`${consts.route}/postsales`} element={<PrivateRoute><AdminPostSales /></PrivateRoute>} />
          <Route path={`${consts.route}/blockchain`} element={<PrivateRoute><AdminBlockchain /></PrivateRoute>} />
          <Route path={`${consts.route}/billing`} element={<PrivateRoute><AdminInvoice /></PrivateRoute>} />
          <Route path={`${consts.route}/admin/producradminlink`} element={<PrivateRoute><QrProduct /></PrivateRoute>} />
          <Route path={`${consts.route}/products-appove/:id`} element={<AdminBrandsApprove />} />
          <Route path={`${consts.route}/products-staff/:id`} element={<AdminBrandStaff />} />
          <Route path={`${consts.route}/products-seller/:id`} element={<AdminBrandSeller />} />
          <Route path={`${consts.route}/admin/create-NFT`} element={<CreateItem />} />
          <Route path={`${consts.route}/products-approved`} element={<AdminProductApproved />} />
          <Route path={`${consts.route}/admin/admin-seller`} element={<AdminSellers />} />
          <Route path={`${consts.route}/seller-auth`} element={<AdminSellersAuth />} />
          <Route path={`${consts.route}/profile`} element={<AdminProfile />} />
          <Route path={`${consts.route}/subadmin`} element={<Subadmin />} />

          <Route path={`${consts.route}/usersList`} element={<SubAdminList />} />


          <Route path={`${consts.route}/brand-/brand-dashboard`} element={<Dashboard />} />



          <Route path={`${consts.route}/user/dashboard`} element={<UserDashboard />} />
          <Route path={`${consts.route}/producrallocate`} element={<ProductAllocate />} />

          <Route path={`${consts.route}/user/productdetails/:id/:email`} element={<ProductDetail />} />


          <Route path={`${consts.route}/user/login`} element={<UserLoginNew />} />
          <Route path={`${consts.route}/user/register`} element={<RegisterNew />} />
          {/* <Route path={`${consts.route}/user/login2`} element={<User1 />} /> */}
          <Route path={`${consts.route}/user/recover`} element={<RecoverNew />} />
          <Route path={`${consts.route}/user/recoveremail`} element={<RecoverEmail />} />

          <Route path={`${consts.route}/user/paswdchange`} element={<PaswdChange />} />
          <Route path={`${consts.route}/user/home`} element={<Home />} />
          <Route path={`${consts.route}/user/myprofile`} element={<MyProfile />} />
          <Route path={`${consts.route}/user/extendwarrenty`} element={<ExtendWarrenty />} />
          <Route path={`${consts.route}/user/extendwarrent2/:userId`} element={<ExtendWarrent2 />} />
          <Route path={`${consts.route}/user/changeowner`} element={<ChangeOwner />} />
          <Route path={`${consts.route}/user/entercode`} element={<EnterCode />} />
          <Route path={`${consts.route}/user/purchasesuccess`} element={<PurchaseSuccess />} />

          <Route path={`${consts.route}/user/purchase-success`} element={<Purchase_Success />} />


          <Route path={`${consts.route}/admin/customer`} element={<Customer />} />

          {/* user page */}

          <Route path={`${consts.route}/user/myorders`} element={<Myorders />} />
          {/* <Route path={`${consts.route}/user/productdetails`} element={<ProductDetail />} /> */}

          <Route path={`${consts.route}/user/generate-certificate1`} element={<GenerateCertificate1 />} />
          <Route path={`${consts.route}/user/generate-certificate2`} element={<GenerateCertificate2 />} />
          <Route path={`${consts.route}/user/generate-certificate3`} element={<GenerateCertificate3 />} />
          <Route path={`${consts.route}/user/myproductlist`} element={<MyProductList />} />
          <Route path={`${consts.route}/user/myproductview`} element={<MyProductView />} />
          <Route path={`${consts.route}/user/premiumbrand`} element={<PremiumBrands />} />
          <Route path={`${consts.route}/user/PremiumChat`} element={<PremiumChat />} />

          /* brand */

          <Route path={`${consts.route}/brand-/blockchain`} element={<BrandBlockChain />} />
          <Route path={`${consts.route}/brand-/billing`} element={<BrandInvoice />} />
          <Route path={`${consts.route}/brand/postsales`} element={<BrandPostSales />} />
          <Route path={`${consts.route}/brand/feedback/:id`} element={<FeedBack />} />

          {/* <Route path={`${consts.route}/brand/postsale`} element={<BrandProducts />} /> */}

        </Routes>
      </Router>

    </>
  );
}

export default App;
